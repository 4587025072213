<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-12">
          <h2 class="font-weight-bolder">เติมเครดิต</h2>
          <hr />
          <div class="mt-2 mb-2 text-center">
            <div class="col-12 text-center mb-2">
              <b-img
                width="100px"
                :src="
                  require('@/assets/images/production/bank/' +
                    dataBank[indexAccount].bankAccountName +
                    '.jpeg')
                "
                rounded="circle"
              />
            </div>
            <div class="col-6 m-auto text-center">
              <label>ชื่อบัญชี</label>
              <b-form-select
                class="text-center"
                v-model="selectedBank"
                :options="optionBank"
              />
            </div>
            <div class="col-6 m-auto text-center">
              <label class="mt-1">ธนาคาร</label>
              <b-form-input class="text-center" v-model="dataBank[indexAccount].bankAccountName" readonly />
            </div>
            <div class="col-6 m-auto text-center">
              <label class="mt-1">เลขบัญชี</label>
              <b-form-input
                class="text-center"
                ref="bankaccountNumber" v-on:focus="$event.target.select()" v-model="dataBank[indexAccount].bankAccountNumber"
                readonly
              />
            </div>
            <b-button variant="info" class="mt-1" @click="copy"
              ><feather-icon icon="CopyIcon" /> Copy</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput, BFormSelect, BButton, BImg } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BFormInput,
    BFormSelect,
    BButton,
    BImg,
    FeatherIcon,
  },
  data() {
    return {
      selectedBank: "",
      indexAccount: 0,
    };
  },
  watch: {
    selectedBank(e) {
      this.indexAccount = this.dataBank.findIndex((x) => x._id === e);
    },
  },
  computed: {
    dataBank() {
      return this.$store.state.bank.listBank;
    },
    optionBank() {
      return this.$store.state.bank.listBank.map((x) => ({
        value: x._id,
        text: x.name,
      }));
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    copy() {
      this.$refs.bankaccountNumber.focus();
      document.execCommand("copy");
      alert("คัดลอกเลขบัญชีเรียบร้อยแล้ว!!");
    },
    loadData() {
      this.$store.dispatch('bank/getBank').then(result => {
        this.selectedBank = result.items[0]._id
      }).catch(error => {
        console.log(error)
      })
    },
  },
};
</script>

<style></style>
